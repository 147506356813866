<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
                :title="$t('company.pageTitle')"
                :breadcrumb="[
                    { text: $t('home'), href: '/' },
                    {
                        text: $t('company.pageTitle'),
                        href: '/admin/companies'
                    },
                    {
                        text: $t('create'),
                        active: true
                    }
                ]"
            />
            <div class="demo-spin-article">
                <div class="row">
                    <div class="col-md-5 tw-mb-5">
                        <Profile
                            v-model="model"
                            :validate="errors"
                            class="tw-h-full"
                            ref="profile"
                        />
                    </div>
                    <div class="col-md-7 tw-mb-5">
                        <ts-panel>
                            <ts-panel-wrapper style="height: 671px;">
                                <Info v-model="model" :validate="errors" />
                            </ts-panel-wrapper>
                        </ts-panel>
                    </div>
                </div>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef"
        >
            <ts-button
                @click.prevent="$router.push({ name: 'company' })"
                class="btn-gray"
            >
                {{ $t("cancel") }}</ts-button
            >
            <ts-button
                color="primary"
                outline
                :waiting="waiting_new"
                :disabled="waiting || waiting_new"
                @click.prevent="onSaveAddNew"
            >
                {{ $t("saveAddNew") }}</ts-button
            >
            <ts-button
                color="primary"
                :waiting="waiting"
                :disabled="waiting_new || waiting"
                @click.prevent="onSave"
            >
                {{ $t("save") }}</ts-button
            >
        </div>
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import Profile from "./components/profile.vue";
import Info from "./components/info.vue";
import { Errors } from "form-backend-validation";

export default {
    name: "companyCreate",
    components: {
        Profile,
        Info
    },
    data() {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            errors: new Errors(),
            model: {
                company_name_kh: "",
                company_name_en: "",
                company_address: "",
                company_phone: "",
                company_email: "",
                company_website: "",
                company_logo: "",
                prefix_code: "",
                company_mission: "",
                company_vision: "",
                core_values: ""
            }
        };
    },
    created() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    methods: {
        async onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            let file_upload_path = await this.$refs.profile.uploadLogo();
            this.$store
                .dispatch(
                    "corporate/company/store",
                    Object.assign({}, this.model, {
                        company_logo: file_upload_path
                    })
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        async onSave() {
            this.errors = new Errors();
            this.waiting = true;
            let file_upload_path = await this.$refs.profile.uploadLogo();
            this.$store
                .dispatch(
                    "corporate/company/store",
                    Object.assign({}, this.model, {
                        company_logo: file_upload_path
                    })
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.clearInput();
                    this.$router.push({ name: "company" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        clearInput() {
            (this.model.company_id = 0),
                (this.model.company_name_kh = ""),
                (this.model.company_name_en = ""),
                (this.model.company_address = null),
                (this.model.company_phone = null),
                (this.model.company_email = null),
                (this.model.company_website = null),
                (this.model.company_logo = ""),
                (this.model.prefix_code = null),
                (this.model.company_mission = null),
                (this.model.company_vision = null),
                (this.$refs.profile.logo_file_src = require("@/assets/default-logo.jpg")),
                (this.model.core_values = null);
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "COMPANY",
                desc: not.text
            });
        }
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        next();
    }
};
</script>
